import myLabel from './MyLabel'
import socialLogin from './SocialLogin'
import tooltip from './Tooltip'
import myDropzone from './MyDropzone'
import myReactTable from './ReactTable'

export const MyLabel = myLabel
export const SocialLogin = socialLogin
export const Tooltip = tooltip
export const MyDropzone = myDropzone
export const ReactTable = myReactTable
