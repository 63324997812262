import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useAuth } from './Context'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { auth } = useAuth()
  const { authentication } = auth

  return <Route {...rest} render={props => (authentication ? <Component {...props} /> : <Redirect to="/login" />)} />
}

export default PrivateRoute
