import moment from 'moment'
import jwt from 'jsonwebtoken'

import {ALFABETO} from "./constantes"

require('dotenv').config()

export const date = () => moment().utcOffset('-0500').format('YYYY-MM-DD')
export const dateTimeS = (date) => String(moment(date).format()).substr(0, 16)
export const orderdate = date => moment(date).format('DD/MM/YYYY')
export const dateTime = dateTime => moment(dateTime).utcOffset('-0500').format('YYYY-MM-DD HH:mm:ss')
export const dateTimeP = dateTime => moment(dateTime).utcOffset('-0500').format('LT')
export const dateTimeT = dateTimeValue => dateTime(dateTimeValue).replace(' ', 'T').substring(0, 16)
export const dateTimeUTC = dateTimeValue => (dateTimeValue ? dateTimeValue.replace('T', ' ').substring(0, 19) : '')
export const dateTimeUTC16 = dateTimeValue => dateTimeValue.substring(0, 19)
export const uploadImg = (file, type, {authentication}, name = null) => {
    const formData = new FormData()
    formData.append('img', file)
    formData.append('type', type)
    formData.append('name', name ? name.toString().split('.')[0] : null)

    return fetch(`${process.env.REACT_APP_API_ECOCONT}/upload`, {
        method: 'POST',
        headers: {
            authentication
        },
        body: formData
    })
}
export const downloadFile = async ({authentication}, url) => {
    return fetch(`${process.env.REACT_APP_API_ECOCONT}/${url}`, {
        method: 'GET',
        headers: {
            authentication
        }
    })
}
export const myToFixed = (number, decimals = 2) => {
    const rounding = Math.round(parseFloat(number) * Math.pow(10, decimals)) / Math.pow(10, decimals)

    return Number(rounding).toFixed(decimals)
}
export const decodeToken = auth => jwt.decode(auth)
export const utilvalidarDni = dni => {
    if (dni === null) return {success: false, mensaje: 'Ingrese el número de DNI'}

    if (dni.length !== 8) return {success: false, mensaje: 'Ha ingresado un DNI con menos de 8 digitos'}

    if (!/^([0-9])*$/.test(dni)) return {success: false, mensaje: 'Ha ingresado letras'}

    return {success: true, mensaje: "Ok"}
}
export const utilvalidarRuc = ruc => {
    ruc = ruc.trim()

    if (ruc === null) return {success: false, mensaje: 'Ingrese el número de RUC'}

    if (ruc.length !== 11) return {success: false, mensaje: 'Ha ingresado un RUC con menos de 11 digitos'}

    if (!/^([0-9])*$/.test(ruc)) return {success: false, mensaje: 'Ha ingresado un RUC con letras'}

    if (!((ruc >= 1e10 && ruc < 11e9) || (ruc >= 15e9 && ruc < 18e9) || (ruc >= 2e10 && ruc < 21e9))) return {
        success: false,
        mensaje: 'RUC no válido!'
    }

    let ultimo = ruc.substring(10, 11)
    let suma = 0
    const factores = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]
    factores.forEach((valor, index) => suma += (Number(ruc.substring(index, index + 1)) * valor))

    let di = Math.trunc(suma / 11);
    let resultado = 11 - (Number(suma) - Number(di) * 11);

    if (resultado === 10) resultado = 0
    if (resultado === 11) resultado = 1

    return Number(ultimo) === resultado ? {success: true, mensaje: 'Ok'} : {success: false, mensaje: 'RUC no válido!'}
}
export const myDate = value => value ? moment(value).utcOffset('-0500').format('YYYY-MM-DD') : ''
export const strRandom = length => Array(length).join().split(',').map(() => ALFABETO.charAt(Math.floor(Math.random() * ALFABETO.length))).join('')
export const myFormatNumber = (number, withCeros = false) => {
    number = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(number)

    return withCeros ? number : (number !== '0.00' ? number : '')
}