import React from 'react'

const AppFooter = () => (
    <div className="app-footer">
        <div className="app-footer__inner">
            <div className="app-footer-left">
        <span>
          copyright © and development {new Date().getFullYear()}{' '}
            <a href="https://alternativascontables.pe" target="_blank" rel="noreferrer">
            Alternativas Contables S.R.L.
          </a>
        </span>
            </div>
            <div className="app-footer-right">
        <span>
          v<strong>2.2.1211</strong>
        </span>
            </div>
        </div>
    </div>
)

export default AppFooter
