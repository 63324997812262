import React, {Fragment, useState} from 'react'
import {Card, CardHeader, CardBody, Col, Row, Input} from 'reactstrap'
import {faChartLine} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import gql from 'graphql-tag'
// Layout
import AppHeader from '../Layout/AppHeader/'
import AppSidebar from '../Layout/AppSidebar/'
import AppFooter from '../Layout/AppFooter/'
// Theme Options
import ThemeOptions from '../Layout/ThemeOptions/'
import MyLabel from '../Components/MyLabel'
import {MyDropzone} from '../Components'
import {useAuth} from '../Context'
import {useEffect} from 'react'

require('dotenv').config()

const Home = () => {
    const {client, addToast, removeAllToasts, auth} = useAuth()

    return (
        <Fragment>
            <ThemeOptions/>
            <AppHeader/>
            <div className='app-main'>
                <AppSidebar/>
                <div className='app-main__outer'>
                    <div className='app-main__inner'>
                        <Card className='main-card'>
                            <CardHeader className='card-header-tab z-index-6'>
                                <FontAwesomeIcon icon={faChartLine} className='text-warning'/>
                            </CardHeader>
                            <CardBody>
                                <Col md={12}>

                                </Col>
                            </CardBody>
                        </Card>
                    </div>
                    <AppFooter/>
                </div>
            </div>
        </Fragment>
    )
}

export default Home
