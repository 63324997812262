import React, {Fragment, useState} from 'react'
import {FormFeedback, Input} from 'reactstrap'

export default function useInput({
                                     typeState, placeholder, initialState, maxLength, readOnly, min, max, disabled,
                                     invalidate, initialMessage, step
}) {
    const [value, setValue] = useState(initialState ? initialState : '')
    const [invalid, setInvalid] = useState(invalidate ? invalidate : false)
    const [message, setMesssage] = useState(initialMessage ? initialMessage : '')

    const inputValue = (
        <Fragment>
            <Input
                value={value || ''}
                onChange={({target}) => setValue(target.value)}
                type={typeState ? typeState : 'text'}
                invalid={invalid}
                placeholder={placeholder ? placeholder : ''}
                maxLength={maxLength ? maxLength : 255}
                bsSize="sm"
                min={min}
                max={max}
                step={step ? step : 1}
                disabled={disabled ? disabled : false}
                readOnly={readOnly ? readOnly : false}
            />
            <FormFeedback>{message}</FormFeedback>
        </Fragment>
    )

    return [value, inputValue, setValue, setInvalid, setMesssage, invalid]
}