export const customSelectStyles = {
    container: (provided, state) => ({
        ...provided,
        padding: 0,
        height: 'fit-content'
    }),
    control: (provided, state) => ({
        ...provided,
        borderWidth: 1,
        minHeight: 'fit-content',
        height: 'fit-content'
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 4
    }),
    clearIndicator: base => ({
        ...base,
        padding: 4
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '25px'
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 6px'
    }),
    input: (provided, state) => ({
        ...provided,
        height: '25px'
    }),
    menu: (provided, state) => ({
        ...provided,
        // width: "max-content",
        borderBottom: '1px dotted pink',
        // zIndex: '9999'
    })
}

export const styleLink = {
    color: '#FFF',
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {textDecoration: 'none'}
}

export const treeMenu = [
    {
        id: 'NCO001',
        title: 'DNI',
        subtitle: 'Consulta de DNI',
        expanded: false,
        children: [
            {
                id: 'NCO001-1',
                title: 'Básico',
                subtitle: '',
            },
            {
                id: 'NCO001-2',
                title: 'Premiun',
                subtitle: '',
            },
            {
                id: 'NCO001-3',
                title: 'Por Nombre',
                subtitle: 'Defined in `children` array belonging to parent',
            },
            {
                id: 'NCO001-4',
                title: 'Platinum',
                subtitle: '',
            },
        ],
    },
    {
        id: 'NCO002',
        title: 'LICENCIAS - MTC',
        subtitle: 'Consulta de DNI',
        expanded: false,
    },
    {
        id: 'NCO003',
        title: 'RUC',
        subtitle: 'Consulta de DNI',
        expanded: false,
        children: [
            {
                id: 'NCO003-1',
                title: 'Reducido',
                subtitle: '',
            },
            {
                id: 'NCO003-2',
                title: 'Básico',
                subtitle: '',
            },
            {
                id: 'NCO003-3',
                title: 'Premium',
                subtitle: 'Defined in `children` array belonging to parent',
            },
        ],
    },
    {
        id: 'NCO004',
        title: 'ESSALUD',
        subtitle: '',
        expanded: false,
    },
    {
        id: 'NCO005',
        title: 'VALIDEZ DE CPE',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'NCO005-1',
                title: 'Validez CPE',
                subtitle: '',
            },
            {
                id: 'NCO005-2',
                title: 'Validez XML',
                subtitle: '',
            }
        ],
    },
    {
        id: 'NCO006',
        title: 'CONSULTA CCI - BN',
        subtitle: '',
        expanded: false,
    },
    {
        id: 'NCO007',
        title: 'TIPO DE CAMBIO',
        subtitle: '',
        expanded: false,
    },
    {
        id: 'NCO008',
        title: 'REMYPE',
        subtitle: '',
        expanded: false,
    },
    {
        id: 'NCO009',
        title: 'OBLIGADOS CPE',
        subtitle: '',
        expanded: false,
    },
    {
        id: 'NSP001',
        title: 'Registro de Compras',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'NSP001-1',
                title: 'Compras 8.1',
                subtitle: '',
            },
            {
                id: 'NSP001-2',
                title: 'Compras 8.2',
                subtitle: '',
            },
            {
                id: 'NSP001-3',
                title: 'Compras 8.3',
                subtitle: '',
            }
        ],
    },
    {
        id: 'NSP002',
        title: 'Registro de Ventas',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'NSP002-1',
                title: 'Ventas 14.1',
                subtitle: '',
            },
            {
                id: 'NSP002-2',
                title: 'Ventas 14.2',
                subtitle: '',
            }
        ],
    },
    {
        id: 'NSP003',
        title: 'Libro Diario',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'NSP003-1',
                title: 'Libro Diario',
                subtitle: '',
            },
            {
                id: 'NSP003-2',
                title: 'PCGE',
                subtitle: '',
            }
        ],
    },
    {
        id: 'NSP004',
        title: 'Libro Diario Simplificado',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'NSP004-1',
                title: 'Libro Diario Simplificado',
                subtitle: '',
            },
            {
                id: 'NSP004-2',
                title: 'PCGE',
                subtitle: '',
            }
        ],
    },
    {
        id: 'NSP005',
        title: 'Libro Mayor',
        subtitle: '',
        expanded: false,
    },
    {
        id: 'NPR001',
        title: 'DETRACCIONES (NPD)',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'NPR001-1',
                title: 'Proveedor - Adquirientes',
                subtitle: '',
            },
            {
                id: 'NPR001-2',
                title: 'Adquiriente - Proveedores',
                subtitle: '',
            }
        ],
    },
    {
        id: 'NPR002',
        title: 'ING RECAUDACIÓN F.V 4702',
        subtitle: '',
        expanded: false,
    },
    {
        id: 'NCV001',
        title: 'Reg. Compras',
        subtitle: '',
        expanded: false,
    },
    {
        id: 'NCV002',
        title: 'Reg. Ventas',
        subtitle: '',
        expanded: false,
    },
    {
        id: 'NPD001',
        title: 'FORM. VIRTUAL 621',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'NPD001-1',
                title: 'RETENCIONES',
                subtitle: '',
            },
            {
                id: 'NPD001-2',
                title: 'PERCEPCIONES',
                subtitle: '',
                expanded: false,
                children: [
                    {
                        id: 'NPD001-2-1',
                        title: 'Percepción en Factura',
                        subtitle: '',
                    },
                    {
                        id: 'NPD001-2-2',
                        title: 'Compr. de Percepción',
                        subtitle: '',
                    }
                ],
            }
        ],
    },
    {
        id: 'NPD002',
        title: 'PDT 3500 - DAOT',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'NPD002-1',
                title: 'Oper. generan Costo/Gasto',
                subtitle: '',
            },
            {
                id: 'NPD002-2',
                title: 'Oper. generan Ingreso',
                subtitle: '',
            }
        ],
    },
    {
        id: 'SCS001',
        title: 'EMPRESAS',
        subtitle: ''
    },
    {
        id: 'SCS002',
        title: 'DATA',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'SCS002-1',
                title: 'Clientes',
                subtitle: '',
            },
            {
                id: 'SCS002-2',
                title: 'Proveedores',
                subtitle: '',
            },
            {
                id: 'SCS002-3',
                title: 'PCGE',
                subtitle: '',
            },
            {
                id: 'SCS002-4',
                title: 'Destinos',
                subtitle: '',
            }
        ],
    },
    {
        id: 'SCS003',
        title: 'REG. DE OPERACIONES',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'SCS003-1',
                title: 'Registro de Compras',
                subtitle: '',
            },
            {
                id: 'SCS003-2',
                title: 'Registro de Ventas',
                subtitle: '',
            },
            {
                id: 'SCS003-3',
                title: 'Asientos Varios',
                subtitle: '',
            },
            {
                id: 'SCS003-4',
                title: 'Cuentas por Pagar',
                subtitle: '',
            },
            {
                id: 'SCS003-5',
                title: 'Cuentas por Cobrar',
                subtitle: '',
            }
        ],
    },
    {
        id: 'SCS004',
        title: 'LIBROS ELECTRÓNICOS',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'SCS004-1',
                title: 'Registro de Compras',
                subtitle: '',
                children: [
                    {
                        id: 'SCS004-1-1',
                        title: 'Compras 8.1',
                        subtitle: '',
                    },
                    {
                        id: 'SCS004-1-2',
                        title: 'Compras 8.2',
                        subtitle: '',
                    }
                ]
            },
            {
                id: 'SCS004-2',
                title: 'Registro de Ventas 14.1',
                subtitle: ''
            },
            {
                id: 'SCS004-3',
                title: 'Libro Diario',
                subtitle: '',
                children: [
                    {
                        id: 'SCS004-3-1',
                        title: 'Libro Diario',
                        subtitle: '',
                    },
                    {
                        id: 'SCS004-3-2',
                        title: 'PCGE',
                        subtitle: '',
                    }
                ]
            },
            {
                id: 'SCS004-4',
                title: 'Libro Diario Simplificado',
                subtitle: '',
                children: [
                    {
                        id: 'SCS004-4-1',
                        title: 'Libro Diario Simplificado',
                        subtitle: '',
                    },
                    {
                        id: 'SCS004-4-2',
                        title: 'PCGE',
                        subtitle: '',
                    }
                ]
            },
            {
                id: 'SCS004-5',
                title: 'Libro Mayor',
                subtitle: ''
            }
        ],
    },
    {
        id: 'SCS005',
        title: 'LIBROS FÍSICOS',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'SCS005-1',
                title: 'Registro de Compras',
                subtitle: '',
            },
            {
                id: 'SCS005-2',
                title: 'Registro de Ventas',
                subtitle: '',
            },
            {
                id: 'SCS005-3',
                title: 'Libro Diario',
                subtitle: '',
            },
            {
                id: 'SCS005-4',
                title: 'Diario Simplificado',
                subtitle: '',
            },
            {
                id: 'SCS005-5',
                title: 'Libro Mayor',
                subtitle: '',
            }
        ],
    },
    {
        id: 'SCS006',
        title: 'HOJA DE TRABAJO',
        subtitle: ''
    },
    {
        id: 'SCS007',
        title: 'ESTADOS FINANCIEROS',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'SCS007-1',
                title: 'Balance General',
                subtitle: '',
            },
            {
                id: 'SCS007-2',
                title: 'E.G. Y P-Naturaleza',
                subtitle: '',
            },
            {
                id: 'SCS007-3',
                title: 'E.G. Y P-Función',
                subtitle: '',
            }
        ],
    },
    {
        id: 'SCS008',
        title: 'UTILITARIOS',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'SCS008-1',
                title: 'Importar Compras (0801)',
                subtitle: '',
            },
            {
                id: 'SCS008-2',
                title: 'Importar Ventas (1401)',
                subtitle: '',
            },
            {
                id: 'SCS008-3',
                title: 'Importar Diario (0501)',
                subtitle: '',
            }
        ]
    },
    {
        id: 'LRC001',
        title: 'Libro Diario',
        subtitle: '',
        expanded: false,
    },
    {
        id: 'LRC002',
        title: 'Libro Mayor',
        subtitle: '',
        expanded: false,
    },
    {
        id: 'LRC003',
        title: 'Diario Simplificado',
        subtitle: '',
        expanded: false,
    },
    {
        id: 'LRC004',
        title: 'Hoja de Trabajo',
        subtitle: '',
        expanded: false,
    },
    {
        id: 'LRC004M1',
        title: 'Estados Financieros',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'LRC004M1-1',
                title: 'Balance General',
                subtitle: '',
            },
            {
                id: 'LRC004M1-2',
                title: 'E.G. y P-Naturaleza',
                subtitle: '',
            },
            {
                id: 'LRC004M1-3',
                title: 'E.G. y P-Función',
                subtitle: '',
            }
        ],
    },
    {
        id: 'LRC005',
        title: 'Plan de Cuentas',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'LRC005-1',
                title: 'PCGE 2010',
                subtitle: '',
            },
            {
                id: 'LRC005-2',
                title: 'PCGE 2020',
                subtitle: '',
            }
        ],
    },
    {
        id: 'NAD001',
        title: 'Usuarios',
        subtitle: '',
        expanded: false,
    },
    {
        id: 'NAD002',
        title: 'Autorización',
        subtitle: '',
        expanded: false,
        children: [
            {
                id: 'NAD002-1',
                title: 'Roles',
                subtitle: '',
            },
            {
                id: 'NAD002-2',
                title: 'Servicios',
                subtitle: '',
            },
            {
                id: 'NAD002-3',
                title: 'Paquetes',
                subtitle: '',
            }
        ]
    },
]

export const LOGO_EMPRESA = '4q2jtjazgkngjpj8v.png'
export const CERTIFICADO_EMPRESA = '4q2jtj8scknkjgpcx.pfx'
export const API_FACT = 'www.alternativacontable.com'
export const SOAP_URL = 'https://e-beta.sunat.gob.pe/ol-ti-itcpfegem-beta/billService?wsdl'
export const USER_SOL = 'MODDATOS'
export const PASSWORD_SOL = 'moddatos'
export const PASSWORD_CER = 'smartb'
export const SOAP_ENVIO = '01'
export const SOAP_TIPO = '01'
export const TIPO_DOCUMENTO = '22b4f87a-fd16-11eb-a22d-5bed6a7bf68b'
export const ALFABETO = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
export const OPERACIONES = [
    {value: '29a8f534-d53c-11eb-b8bc-0242ac130003', label: "Adq Grav Destinadas a operac grav y/o de Exportación"},
    {
        value: '3f3fab2c-d53c-11eb-b8bc-0242ac130003',
        label: "Adq Grav Destinadas a operac grav, no gravadas y/o de Export"
    },
    {value: '41eb1b7c-d53c-11eb-b8bc-0242ac130003', label: "Adq Grav Destinadas a operac no gravadas"},
    {value: '4454417c-d53c-11eb-b8bc-0242ac130003', label: "Adquisiciones no Gravadas"}
]
export const AFECTACIONES = [
    {value: '48a1bf3e-d53c-11eb-b8bc-0242ac130003', label: "Detracciones", name: 'DETRACCIÓN'},
    {value: '4ba4219a-d53c-11eb-b8bc-0242ac130003', label: "Retenciones", name: 'RETENCIÓN'},
    {value: '4f3f0324-d53c-11eb-b8bc-0242ac130003', label: "Percepciones", name: 'PERCEPCIÓN'},
    {value: '537d9770-d53c-11eb-b8bc-0242ac130003', label: "Impuesto a la Renta", name: 'IMPUESTO A LA RENTA'}
]
export const PAGINATION = [{value: 5, label: '5 Filas'}, {value: 10, label: '10 Filas'}, {value: 20, label: '20 Filas'},
    {value: 25, label: '25 Filas'}, {value: 50, label: '50 Filas'}, {value: 100, label: '100 Filas'}]
export const BUSQUEDA_MFGCP = [{value: '1', label: 'Mes'}, {value: '2', label: 'Fecha'}, {value: '3', label: 'Glosa'},
    {value: '4', label: 'CUO'}, {value: '5', label: 'Proveedor'}]
export const TIPOS_CUENTA = [{value: '1', label: 'DINERO EN EFECTIVO'}, {value: '2', label: 'ENTIDADES FINANCIERAS'}]
export const MONEDAS = [{value: 'ff6664ae-a779-11eb-85cf-40b0344a6892', label: 'SOLES', code: 'PEN'},
    {value: 'ff668bb4-a779-11eb-85d0-40b0344a6892', label: 'DÓLARES AMERICANOS', code: 'USD'}]
export const ICBPER = 0.30