import React, {Fragment,useState} from 'react'
import TextareaAutosize from "react-textarea-autosize"
import {FormFeedback} from "reactstrap"

export default function useTextareaAutosize({initialState, placeholder, minRows, maxRows, initialMessage, maxLength}) {
    const [value, setValue] = useState(initialState ? initialState : '')
    const [invalid, setInvalid] = useState(false)
    const [message, setMesssage] = useState(initialMessage ? initialMessage : '')

    const texTareaValue = (
        <Fragment>
            <TextareaAutosize
                className={`form-control p-0 ${invalid ? 'is-invalid' : ''}`}
                value={value}
                placeholder={placeholder}
                onChange={({target}) => setValue(target.value)}
                minRows={minRows ? minRows : 1}
                maxRows={maxRows ? maxRows : 3}
                maxLength={maxLength ? maxLength : 1000}
                // autoFocus
            />
            <FormFeedback>{message}</FormFeedback>
        </Fragment>

    )

    return [value, texTareaValue, setValue, setInvalid, setMesssage]
}
