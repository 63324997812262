import React, {Suspense, lazy, useEffect} from 'react'
import {Route, Redirect} from 'react-router-dom'
import Loader from 'react-loaders'
import {ApolloClient} from 'apollo-client'
import gql from 'graphql-tag'
import {ApolloLink, Observable} from 'apollo-link'
import {onError} from 'apollo-link-error'
import {HttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {getMainDefinition} from 'apollo-utilities'
import {WebSocketLink} from 'apollo-link-ws'
import ApolloLinkTimeout from 'apollo-link-timeout'
import {useToasts} from 'react-toast-notifications'
// import {createUploadLink} from 'apollo-upload-client'

import {AuthContext} from '../../Context'
import PrivateRoute from '../../PrivateRoute'
import Login from '../../Views/Auth/Login'
import Register from "../../Views/Auth/Register"
import Reset from '../../Views/Auth/Reset'
import Verify from '../../Views/Auth/Verify'
import Home from '../../Views/Home'
import useLocalStorage from '../../hooks/useLocalStorage'

const App = lazy(() => import('../../Views/App'))
const Facturador = lazy(() => import('../../Views/Facturador'))
const Admin = lazy(() => import('../../Views/Admin'))

require('dotenv').config()
let client

const AppMain = () => {
    const {addToast, removeAllToasts} = useToasts()

    const [auth, setAuth] = useLocalStorage('Auth', {})
    const [company, setCompany] = useLocalStorage('Company', {})

    const setClient = auth => {
        setAuth(auth)
        const {authentication} = auth

        const timeoutLink = new ApolloLinkTimeout(120000) // Milliseconds

        client = new ApolloClient({
            link: ApolloLink.from([
                onError(({graphQLErrors, networkError, operation, forward}) => {
                    if (networkError) {
                        const {statusCode} = networkError

                        switch (statusCode) {
                            case 401:
                                // User access token has expired
                                // We assume we have both tokens needed to run the async request
                                // Let's refresh token through async request
                                return new Observable(observer => {
                                    client
                                        .query({
                                            query: gql`
                                                query refreshToken($authentication: String!) {
                                                  refresh(authentication: $authentication) {
                                                    authentication
                                                    authorization
                                                    refresh
                                                  }
                                                }
                                              `,
                                            variables: {
                                                authentication: `Bearer ${authentication}`
                                            },
                                            fetchPolicy: 'no-cache'
                                        })
                                        .then(response => {
                                            const {refresh} = response.data
                                            if (response.error || refresh === null) {
                                                setAuth({})
                                                window.location.href = '/login'
                                                return
                                            }

                                            operation.setContext({
                                                headers: {
                                                    Authentication: `Bearer ${refresh.authentication}`
                                                }
                                            })

                                            forward(operation).subscribe({
                                                next: observer.next.bind(observer),
                                                error: observer.error.bind(observer),
                                                complete: observer.complete.bind(observer)
                                            })

                                            setClient(refresh)
                                        })
                                        .catch(error => {
                                            // No refresh or client token available, we force user to login
                                            observer.error(error)
                                            console.log(error)

                                            setAuth({})
                                            // window.location.href = '/login'
                                        })
                                })
                            case 403:
                                addToast('No tiene los privilegios...', {appearance: 'error', autoDismissTimeout: 5000})

                                break
                            default:
                                break
                        }
                    }
                }),
                ApolloLink.split(({query}) => {
                        const {kind, operation} = getMainDefinition(query)
                        return kind === 'OperationDefinition' && operation === 'subscription'
                    },
                    new WebSocketLink({
                        uri: `wss://${process.env.REACT_APP_WS}/graphql`,
                        options: {
                            reconnect: true,
                            connectionParams: {
                                headers: {
                                    Authentication: `Bearer ${authentication}`
                                }
                            }
                        }
                    }),
                    new HttpLink({
                        uri: `${process.env.REACT_APP_API_ECOCONT}/api`,
                        headers: {
                            Authentication: `Bearer ${authentication}`
                        }
                    })
                )
            ]),
            cache: new InMemoryCache(),
            name: 'Cliente de GRAPHQL para Aplicativos',
            version: '1.3',
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache'
                }
            }
        })
    }

    const toast = {
        success: (message, {autoClose} = {}) => {
            if (autoClose) addToast(message, {appearance: 'success', autoDismissTimeout: autoClose})
            else addToast(message, {appearance: 'success', autoDismiss: false})
        },
        error: (message, {autoClose}) => addToast(message, {appearance: 'error', autoDismissTimeout: autoClose}),
        warning: (message, {autoClose}) => addToast(message, {appearance: 'warning', autoDismissTimeout: autoClose}),
        info: (message, {autoClose}) => addToast(message, {appearance: 'info', autoDismissTimeout: autoClose})
    }

    useEffect(() => {
        setClient(auth)
        setCompany(company)
    }, [])

    return client ? (
        <AuthContext.Provider value={{auth, setAuth, client, setClient, toast, removeAllToasts, company, setCompany}}>
            <Route exact path='/login' component={Login}/>
            <Route exact path="/register" component={Register}/>
            <Route exact path='/password/reset' component={Reset}/>
            <Route exact path='/password/reset/:token/:user' component={Reset}/>
            <Route exact path='/account/verify/:token' component={Verify}/>

            <PrivateRoute path='/home' component={Home}/>

            <Suspense fallback={
                <div className='loader-container'>
                    <div className='loader-container-inner'>
                        <div className='text-center'>
                            <Loader type='ball-pulse-rise'/>
                        </div>
                        <h6 className='mt-5'>Cargando los Componentes...</h6>
                    </div>
                </div>
            }>
                <Route path='/app' component={App}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Cargando los Componentes...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/cpe" component={Facturador}/>
            </Suspense>

            <Suspense fallback={
                <div className='loader-container'>
                    <div className='loader-container-inner'>
                        <div className='text-center'>
                            <Loader type='ball-pulse-rise'/>
                        </div>
                        <h6 className='mt-5'>Cargando los Componentes...</h6>
                    </div>
                </div>
            }>
                <Route path='/admin' component={Admin}/>
            </Suspense>

            <Route exact path='/' render={() => <Redirect to={'/login'}/>}/>
        </AuthContext.Provider>
    ) : (
        <></>
    )
}

export default AppMain
