import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

const Tooltip = ({ id, name }) => (
  <UncontrolledTooltip placement="left" target={id}>
    {name}
  </UncontrolledTooltip>
)

export default Tooltip
