import gql from "graphql-tag"

const User = {
    create: (client, {email, nombres, a_paterno, a_materno, avatar, password, role_id}, recursos) => {
        return client.mutate({
            mutation: gql`mutation createUser(
                    $email: String!, 
                    $nombres: String!, 
                    $a_paterno: String, 
                    $a_materno: String, 
                    $avatar: String, 
                    $password: String!, 
                    $role_id: String
                 ) {
                  createUser(
                    email: $email, 
                    nombres: $nombres, 
                    a_paterno: $a_paterno, 
                    a_materno: $a_materno, 
                    avatar: $avatar, 
                    password: $password,
                    role_id: $role_id
                    ) {
                    ${recursos}
                  }
                }`,
            variables: {
                email,
                nombres,
                a_paterno,
                a_materno,
                avatar,
                password,
                role_id: String(role_id)
            }
        })
    },
    getAll: (client, recursos) => {
        return client.query({
            query: gql`{
              users {
                ${recursos}
              }
            }`,
            fetchPolicy: "no-cache"
        })
    },
    getById: (client, id, recursos) => {
        return client.query({
            query: gql`query getUser($id: String!){
                user(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {
                id: String(id)
            },
            fetchPolicy: "no-cache"
        })
    },
    update: (client, {
        id, email, nombres, a_paterno, a_materno, avatar, password, role_id
    }, recursos) => {
        return client.mutate({
            mutation: gql`mutation updateUser($id: String!, $update: JSON!){
                updateUser(id: $id, update: $update) {
                    ${recursos}
                  }
                }`,
            variables: {
                id: String(id),
                update: {
                    email,
                    nombres,
                    a_paterno,
                    a_materno,
                    avatar,
                    password,
                    role_id
                }
            }
        })
    },
    delete: (client, id, recursos) => {
        return client.mutate({
            mutation: gql`mutation deleteUser($id: String!){
                deleteUser(id: $id) {
                    ${recursos}
                  }
                }`,
            variables: {
                id: String(id),
            }
        })
    },
}

export default User