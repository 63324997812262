import React, {Fragment, useState} from 'react'
import {Link, Redirect} from 'react-router-dom'
import Slider from 'react-slick'
import {Col, Row, Button, Form, FormGroup, Label, Input} from 'reactstrap'
import {faUser} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import bg1 from '../../assets/utils/images/originals/bg1.jpg'
import bg2 from '../../assets/utils/images/originals/bg2.jpg'
import bg3 from '../../assets/utils/images/originals/bg3.jpg'

import {useAuth} from '../../Context'
import Auth from '../../Components/Models/Auth'

const Reset = ({match}) => {
    const {token, user} = match.params

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true
    }

    const {auth, client, setClient, addToast, removeAllToasts} = useAuth()

    const [email, setEmail] = useState(user)
    const [password, setPassword] = useState('')
    const [password_confirm, setPasswordConfirm] = useState('')

    const reset = user ? 'initial' : 'none'

    const postReset = () => {
        if (user) {
            if (email === '') {
                addToast('Ingrese un Email!', {appearance: 'error', autoDismissTimeout: 5000})
                return
            }
            if (password.length < 6 || password.length > 15) {
                addToast('La contraseña debe ser mayor de 5 y menor de 16 caracteres', {
                    appearance: 'error',
                    autoDismissTimeout: 5000
                })
                return
            }

            if (password !== password_confirm) {
                addToast('La Contraseña no coincide!', {appearance: 'error', autoDismissTimeout: 5000})
                setPassword('')
                setPasswordConfirm('')
                return
            }

            addToast('Procesando...', {appearance: 'success', autoDismiss: false})

            Auth
                .passwordReset(client, {email, password, token}, 'authentication, authorization, refresh')
                .then(response => {
                    const {passwordReset} = response.data
                    removeAllToasts()
                    switch (passwordReset.authentication) {
                        case 'old':
                            addToast('¡Token Caducado!', {appearance: 'error', autoDismissTimeout: 5000})
                            break
                        case 'cersa.org.pe':
                            window.location = 'https://cersa.org.pe/aulavirtual/'
                            break
                        default:
                            setClient(passwordReset)
                            break
                    }
                })
                .catch(({message}) => {
                    removeAllToasts()
                    addToast(message, {appearance: 'error', autoDismissTimeout: 5000})
                })
        } else {
            addToast('Procesando...', {appearance: 'success', autoDismiss: false})

            Auth.reset(client, {email}, 'authentication')
                .then(response => {
                    const {reset} = response.data
                    removeAllToasts()

                    if (reset === null) {
                        addToast('¡Ingrese un email Válido!', {appearance: 'error', autoDismissTimeout: 5000})
                        return
                    }

                    if (reset.authentication === 'reset') {
                        addToast('Aún no ha verificado su email!', {appearance: 'error', autoDismissTimeout: 5000})
                        return
                    }

                    addToast('¡Te hemos enviado por correo el enlace para restablecer tu contraseña!', {
                        appearance: 'success',
                        autoDismissTimeout: 5000
                    })
                })
                .catch(({message}) => {
                    removeAllToasts()
                    addToast(message, {appearance: 'error', autoDismissTimeout: 5000})
                })
        }
    }

    return auth.authentication ? (
        <Redirect to={'/home'}/>
    ) : (
        <Fragment>
            <div className="h-100">
                <Row className="h-100 no-gutters">
                    <Col lg="8" className="d-none d-lg-block">
                        <div className="slider-light">
                            <Slider {...settings}>
                                <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div
                                        className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg1 + ')'
                                        }}
                                    />
                                    <div className="slider-content">
                                        <h3>Sistema para la gestión de Cursos </h3>
                                        <p>Se administrará los cursos del Instituto Cersa.</p>
                                    </div>
                                </div>
                                <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div
                                        className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg3 + ')'
                                        }}
                                    />
                                    <div className="slider-content">
                                        <h3>Automatizamos</h3>
                                        <p>procesos de matricula, cursos y alumnado</p>
                                    </div>
                                </div>
                                <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div
                                        className="slide-img-bg opacity-6"
                                        style={{
                                            backgroundImage: 'url(' + bg2 + ')'
                                        }}
                                    />
                                    <div className="slider-content">
                                        <h3>Administramos</h3>
                                        <p>Matriculas, alumnos, cursos.</p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </Col>
                    <Col lg="4" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                        <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                            <img src="/logo.png" alt="Logo"/>
                            <Row className="divider"/>
                            <h4 className="mb-0">
                                <span>Restablecer Contraseña</span>
                            </h4>
                            <h6 className="mt-3">
                                ¿Ya tienes una cuenta?{' '}
                                <Link to={'/login'} className="text-primary">
                                    Login
                                </Link>
                            </h6>
                            <Row className="divider"/>
                            <Form>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Email</Label>
                                            <Input
                                                type="email"
                                                value={email}
                                                onChange={({target}) => setEmail(target.value)}
                                                placeholder="Email"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} style={{display: `${reset}`}}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Nueva Contraseña</Label>
                                            <Input type="password" value={password}
                                                   onChange={({target}) => setPassword(target.value)}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} style={{display: `${reset}`}}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Confirmar Nueva Contraseña</Label>
                                            <Input
                                                type="password"
                                                value={password_confirm}
                                                onChange={({target}) => setPasswordConfirm(target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                            <Row className="divider"/>
                            <div className="d-flex align-items-center">
                                <div className="ml-auto">
                                    <Button color="primary" size="lg" onClick={postReset}>
                                        <FontAwesomeIcon icon={faUser}/> Resetear Contraseña
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}

export default Reset
