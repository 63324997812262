/* eslint-disable */
import React, {Fragment, useState, useEffect} from 'react'
import Ionicon from 'react-ionicons'
import {UncontrolledDropdown, DropdownToggle, DropdownMenu} from 'reactstrap'
import Tabs from 'react-responsive-tabs'

import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg'

// Dropdown Tabs Content
import MatriculasPendientes from './TabsContent/MatriculasPendientes'
import PacksPendientes from './TabsContent/PacksPendientes'
import {useAuth} from '../../../Context'

const Notificaciones = () => {
    const {client, addToast, removeAllToasts, auth} = useAuth()

    const [matriculas, setMatriculas] = useState([])
    const [packs, setPacks] = useState([])

    const tabsContent = [
        {
            title: `Matrículas (${matriculas.length})`,
            content: <MatriculasPendientes {...{matriculas}} />
        },
        {
            title: `Packs (${packs.length})`,
            content: <PacksPendientes {...{packs}} />
        }
    ]
    /*
      useEffect(() => {
        Matricula.getAllPendientes(
          client,
          'id, curso_id, alumno_id, created_at, alumno {id, dni, email, nombres, a_paterno, a_materno}, curso {id, nombre_corto}'
        )
          .then(response => {
            const { matriculasPendientes } = response.data

            setMatriculas(matriculasPendientes)
          })
          .catch(({ message }) => {
            addToast(message, { appearance: 'error', autoDismissTimeout: 5000 })
          })

        Pack.getAllPendientes(
          client,
          'id, payu_id, created_at, alumno_email, importe, observaciones, alumno {id, dni, email, nombres, a_paterno, a_materno}'
        )
          .then(response => {
            const { packsPendientes } = response.data

            setPacks(packsPendientes)
          })
          .catch(({ message }) => {
            addToast(message, { appearance: 'error', autoDismissTimeout: 5000 })
          })
      }, [])
    */
    /*
    useEffect(() => {
        const subscribe = Matricula.newPendiente(
            client,
            'id, curso_id, alumno_id, created_at, alumno {id, dni, email, nombres, a_paterno, a_materno}, curso {id, nombre_corto}'
        ).subscribe({
            next(response) {
                const {newPendiente} = response.data

                setMatriculas(newPendiente)
            },
            error(error) {
                // console.log(error)
            },
            complete(complete) {
                console.log(complete)
            }
        })
        return () => subscribe.unsubscribe()
    }, [])
     */

    function getTabs() {
        return tabsContent.map((tab, index) => ({
            title: tab.title,
            getContent: () => tab.content,
            key: index
        }))
    }

    return (
        <Fragment>
            <div className='header-dots'>
                <UncontrolledDropdown>
                    <DropdownToggle className='p-0 mr-2' color='link'>
                        <div className='icon-wrapper icon-wrapper-alt rounded-circle'>
                            <div
                                className={`icon-wrapper-bg bg-${
                                    matriculas.length === 0 && packs.length === 0 ? 'success ' : 'danger'
                                }`}
                            />
                            <Ionicon
                                beat={matriculas.length > 0 || packs.length > 0}
                                color={matriculas.length === 0 && packs.length === 0 ? '#3ac47d ' : '#d92550'}
                                fontSize='23px'
                                icon='md-notifications-outline'
                            />
                            {matriculas.length > 0 || packs.length > 0 ? (
                                <div className='badge badge-dot badge-dot-sm badge-danger'>Notificaciones</div>
                            ) : (
                                ''
                            )}
                        </div>
                    </DropdownToggle>

                    <DropdownMenu right className='dropdown-menu-xl rm-pointers'>
                        <div className='dropdown-menu-header mb-0'>
                            <div className='dropdown-menu-header-inner bg-deep-blue'>
                                <div
                                    className='menu-header-image opacity-1'
                                    style={{
                                        backgroundImage: 'url(' + city3 + ')'
                                    }}
                                />
                                <div className='menu-header-content text-dark'>
                                    <h5 className='menu-header-title'>Notificaciones</h5>
                                </div>
                            </div>
                        </div>
                        <Tabs tabsWrapperClass='body-tabs body-tabs-alt' transform={false} showInkBar={true}
                              items={getTabs()}/>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        </Fragment>
    )
}

export default Notificaciones
