import React, {useState} from 'react'
import cx from 'classnames'

export default function useSwitch({initialState}) {
    const [value, setValue] = useState(initialState ? initialState : false)

    const inputValue = (
        <div className="switch has-switch mt-1" data-on-label="SI" data-off-label="NO" onClick={() => setValue(!value)}>
            <div className={cx('switch-animate', {'switch-on': value, 'switch-off': !value})}>
                <input type="checkbox"/>
                <span className="switch-left">SI</span>
                <label>&nbsp;</label>
                <span className="switch-right">NO</span>
            </div>
        </div>
    )

    return [value, inputValue, setValue]
}
