module.exports = {
    HomeNav: [
        {
            id: 'NHO001',
            icon: 'pe-7s-home',
            label: 'Home',
            to: '/home'
        }
    ],
    AppNav: [
        {
            id: 'GEN001',
            icon: 'pe-7s-network',
            label: 'Empresas',
            to: '/app/empresas'
        }
    ],
    FacturacionNav: [
        {
            id: 'FAC001',
            icon: 'pe-7s-culture',
            label: 'Emitir CPE',
            to: '/cpe/facturador'
        },
        {
            id: 'FAC002',
            icon: 'pe-7s-culture',
            label: 'Listado de CPE',
            to: '/cpe/facturas'
        },
        {
            id: 'FAC003',
            icon: 'pe-7s-culture',
            label: 'Envios TXT-CPE',
            to: '/cpe/archivos'
        },
        {
            id: 'FAC004',
            icon: 'pe-7s-box1',
            label: 'Productos',
            to: '/cpe/productos'
        },
        {
            id: 'FAC005',
            icon: 'pe-7s-user',
            label: 'Clientes',
            to: '/cpe/clientes'
        }
    ],
     RegistroNav:[
         {
             id: 'REG001',
             icon: 'pe-7s-culture',
             label: 'Registro de ventas',
             to: '/cpe/registro'
         },
     ]
}