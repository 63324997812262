import React, {Fragment} from 'react'
import {withRouter} from 'react-router-dom'
import MetisMenu from 'react-metismenu'

import {useAuth} from '../../Context'
import {HomeNav, AppNav, FacturacionNav, RegistroNav} from './navItems'

const Nav = () => {
    const {auth} = useAuth()

    return (
        <Fragment>
            <MetisMenu content={HomeNav} activeLinkFromLocation className="vertical-nav-menu mt-2" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>
            <MetisMenu content={AppNav} activeLinkFromLocation className="vertical-nav-menu mt-2" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>

            <h5 className={`app-sidebar__heading`}>FACTURADOR</h5>
            <MetisMenu content={FacturacionNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>
            <h5 className={`app-sidebar__heading`}>REPORTES</h5>
            <MetisMenu content={RegistroNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>
        </Fragment>
    )
}

export default withRouter(Nav)
