import React, {Fragment} from 'react'
import cx from 'classnames'
import {connect} from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {Col, Row} from 'reactstrap'
import moment from "moment"
import 'moment/locale/es'

import HeaderLogo from '../AppLogo'
import Notificaciones from './Components/Notificaciones'
import UserBox from './Components/UserBox'
import {useAuth} from "../../Context"

const Header = ({headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow}) => {
    const {company} = useAuth()

    const viewEmpresa = () => (
        <Fragment>
            {company && company.ruc ?
                <Row className="text-center mr-0">
                    <Col sm="12" md="12" xl="12">
                        <b>{company.ruc} - {company.local ? company.local.direccion : ''} - {moment(company.periodo, 'YYYY-MM').format("MMMM YYYY").toUpperCase()}</b>
                    </Col>
                </Row> : <></>
            }
        </Fragment>
    )

    return (
        <Fragment>
            <ReactCSSTransitionGroup component='div'
                                     className={cx('app-header', headerBackgroundColor, {'header-shadow': enableHeaderShadow})}
                                     transitionName='HeaderAnimation' transitionAppear={true}
                                     transitionAppearTimeout={1500} transitionEnter={false} transitionLeave={false}>
                <HeaderLogo/>

                <div className={cx('app-header__content', {'header-mobile-open': enableMobileMenuSmall})}>
                    <div className='app-header-right'>
                        {viewEmpresa()}
                        <Notificaciones/>
                        <UserBox/>
                    </div>
                </div>
            </ReactCSSTransitionGroup>
        </Fragment>
    )
}

const mapStateToProps = ({ThemeOptions}) => ({
    enableHeaderShadow: ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: ThemeOptions.enableMobileMenuSmall
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
